import { TopNumbers } from "./TopNumbers";
import { TopIcons } from "./TopIcons";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { MarketingFeaturesTile } from "../MarketingFeaturesTile/MarketingFeaturesTile";
import styles from "./Promo.module.scss";
import { ImageSet } from "@bloomreach/spa-sdk";

export const PromoSection = ({ topSection, tile, page }: PromoFCProps) => {
  const [contentType, setContentType] = useState<string | null>(null);

  useEffect(() => {
    if (topSection?.length) {
      setContentType(topSection[0]?.contentType);
    }
  }, [topSection]);

  return (
    <>
      {contentType !== null && (
        <>
          {contentType === "brxsaas:pmNumberedSection" &&
            topSection !== undefined && <TopNumbers data={topSection[0]} />}
          {contentType === "brxsaas:pmIconTopPromo" &&
            topSection !== undefined && (
              <TopIcons data={topSection[0]} page={page} />
            )}
        </>
      )}

      <section>
        {tile?.map((card) => {
          const { title, caption, ctaButtons, image, position } = card;

          const imgPosition = position?.selectionValues[0].key;

          const imageCleared =
            image && page.getContent<ImageSet>(image)?.getOriginal();

          return (
            <div key={uuidv4()} className={styles.tilesSection}>
              <MarketingFeaturesTile
                title={title}
                content={caption}
                ctaButtons={ctaButtons}
                image={imageCleared}
                page={page}
                imagePosition={imgPosition}
              />
            </div>
          );
        })}
      </section>
    </>
  );
};
