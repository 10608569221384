import styles from "./Disclaimer.module.scss";
import clsx from "clsx";
import { useState, useRef, useEffect, useContext } from "react";
import { BrRichTextContent } from "../BrRichTextContent";
import { BrComponentContext } from "@bloomreach/react-sdk";
import { MetaFlagsContext } from "state_management/Contexts";

interface ContentParams {
  contentType: string;
  value: string;
}

export const Disclaimer = ({ title, page }: Disclaimers) => {
  const [showAll, setShowAll] = useState(true);
  const [maxHeight, setMaxHeight] = useState<string>();
  const [docContent, setDocContent] = useState<ContentParams | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const component = useContext(BrComponentContext);
  const { content } = component?.getModels<any>() || {};
  const document = content && page.getContent<Document>(content);

  const flags = useContext(MetaFlagsContext);

  useEffect(() => {
    if (document !== null) {
      const { disclaimer } = document?.getData() || {};
      disclaimer
        ? setDocContent(disclaimer)
        : setDocContent({
            contentType: "",
            value:
              "Wrong file format. Please choose a file with the type of PM - Disclaimer Document (pmDocDisclaimer)",
          });
    } else {
      setDocContent(null);
    }
  }, [document]);

  useEffect(() => {
    if (!docContent) {
      return;
    }

    const container = ref.current as HTMLElement;
    setMaxHeight(container.offsetHeight + "px");
  }, [docContent]);

  useEffect(() => {
    const container = ref.current as HTMLElement;

    window.addEventListener("resize", () => {
      setShowAll(true);
      setMaxHeight("none");
      setTimeout(() => {
        setMaxHeight(container.offsetHeight + "px");
      }, 500);
    });
  }, []);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.wrapperNew]: flags?.flags && flags.flags.includes("new"),
      })}
    >
      <aside
        id="disclaimers"
        className={clsx(styles.disclaimer, {
          [styles.disclaimerNew]: flags?.flags && flags.flags.includes("new"),
        })}
      >
        <div
          className={clsx(styles.disclaimerControls, {
            [styles.disclaimerControlsNew]:
              flags?.flags && flags.flags.includes("new"),
          })}
        >
          <h4 className={styles.disclaimerTitle}>
            {title !== "" ? title : "Disclaimers"}
          </h4>
          <button
            className={styles.disclaimerButton}
            aria-expanded={showAll}
            aria-controls="disclaimer-content"
            onClick={() => setShowAll(!showAll)}
          >
            <span
              className={clsx({
                [styles.minusSign]: showAll,
              })}
            >
              {showAll ? "Hide All" : "Show All"}
            </span>
          </button>
        </div>
        <div
          id="disclaimer-content"
          ref={ref}
          className={styles.disclaimerContent}
          style={{ maxHeight: showAll ? maxHeight : "0px" }}
        >
          {docContent !== null && (
            <BrRichTextContent
              page={page}
              content={{ html: docContent.value }}
            />
          )}
        </div>
      </aside>
    </div>
  );
};
