import Image from "next/legacy/image";
import styles from "./ScrollableTiles.module.scss";

export function Tile({
  tileData,
}: {
  tileData: ScrollableTilesProps["pmaccessoriestile"][number];
}) {
  return (
    <section className={styles.componentTile}>
      <figure className={styles.imageContainer}>
        <Image
          className={styles.componentTileImage}
          src={
            tileData?.tileImage
              ? tileData?.tileImage
              : "/services/printing/static-pages/images/placeholders/img-placeholder.png"
          }
          alt=""
          draggable="false"
          layout="fill"
          objectFit="cover"
        />
      </figure>
      <figcaption>
        <h3 className={styles.componentTileHeader}>
          {tileData.title ? tileData.title : "Section title"}
        </h3>
        <p className={styles.componentTileCaption}>
          {tileData.caption ? tileData.caption : "Section caption text"}
        </p>
      </figcaption>
    </section>
  );
}
