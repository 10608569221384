import { BrPageContext } from "@bloomreach/react-sdk";
import { Document, Page } from "@bloomreach/spa-sdk";
import { useMeta } from "hooks/useMeta";
import { useContext } from "react";
import { PathContext } from "state_management/Contexts";
import { bodyDataPair, bodyDataTitle } from "../Meta/BodyData";

export const BreadcrumbsList = ({ context }: MetaProps) => {
  const page: Page | undefined = useContext(BrPageContext);
  const { path, search } = useContext(PathContext);
  const contextPath = path + search;
  const document = page?.getDocument<Document>();
  const { pmMeta }: any = document?.getData();
  useMeta(contextPath, pmMeta, context, bodyDataPair, bodyDataTitle);
  return <></>;
};
