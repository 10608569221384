import styles from "./PrimaryDropdown.module.scss";
import { BackButton, SidebarHead, StaplesLinkSection } from "../../utils";
import { PrimaryTopLevelItem } from "./PrimaryTopLevelItem";
import { useContext, useRef } from "react";
import { Divider } from "@/components/utils/Divider";
import { ProductsTopLevelProps } from "../types";
import { RikContext } from "state_management/Contexts";

export const PrimaryTopLevel = ({
  parentItem,
  topLevelData,
  isDesktop,
  dialogOpen,
  setLevel,
  setParentLevel,
  setItemId,
  itemId,
  staplesCaption,
  hideStaplesRik,
  isActive,
}: ProductsTopLevelProps) => {
  const parentLevelSetup = () => {
    setParentLevel && setParentLevel("initial");
  };

  const containerRef = useRef<HTMLDivElement | null>(null);
  const rik = useContext(RikContext);

  return (
    <article className={styles.container} ref={containerRef}>
      <div className={styles.containerTop}>
        {!isDesktop && dialogOpen && (
          <SidebarHead dialogOpen={dialogOpen} clickFunction={parentLevelSetup}>
            <div data-testid="back-button">
              <BackButton />
            </div>
          </SidebarHead>
        )}
        <section className={styles.products}>
          {topLevelData &&
            topLevelData.map((item, index) => {
              const itemProps = {
                parentItem: `${parentItem} > 2nd Level`,
                itemData: item,
                setItemId: setItemId,
                setLevel: setLevel,
                isDesktop: isDesktop,
                itemId: itemId,
                isActive: isActive,
              };
              return (
                <PrimaryTopLevelItem
                  key={index}
                  {...itemProps}
                  data-testid="primary-top-level-item"
                />
              );
            })}
        </section>
      </div>
      <div className={styles.containerBottom}>
        {isDesktop && <Divider marginVar={8} />}
        {!isDesktop && <Divider marginVar={16} />}

        {((rik && !hideStaplesRik) || !rik) && (
          <StaplesLinkSection
            caption={staplesCaption}
            parentItem={`${parentItem} > 2nd Level`}
          />
        )}
      </div>
    </article>
  );
};
