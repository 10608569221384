import styles from "./HeroGallery.helpers.module.scss";
import clsx from "clsx";
import Image from "next/image";
import { getClassNames } from "services/getClassNames";
import {
  BadgeProps,
  GridItemProps,
  CtaProps,
  BackgroundImagesProps,
  BlockTextProps,
  CalloutProps,
} from "./types";
import { HeroGalleryTagManager } from "./HeroGalleryTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { useContext } from "react";
import { linkAttributes } from "services/linkAttributes";

export function Grid({ children, style }: WrapperProps) {
  const classNames = style ? getClassNames(style, styles) : undefined;

  return <ul className={clsx(styles.grid, classNames)}>{children}</ul>;
}

export function GridItem({ children, style, inlineStyle }: GridItemProps) {
  const classNames = style ? getClassNames(style, styles) : undefined;

  return (
    <li
      className={clsx(styles.gridItem, classNames)}
      style={{
        color: inlineStyle?.color,
        backgroundColor: inlineStyle?.backgroundColor,
      }}
    >
      {children}
    </li>
  );
}

export function ContentWrapper({ children }: WrapperProps) {
  return <div className={styles.contentWrapper}>{children}</div>;
}

export function Badge({ label, color }: BadgeProps) {
  return (
    <span
      className={clsx(styles.badge, {
        [styles.badgeInverse]: color === "Black",
      })}
    >
      {label}
    </span>
  );
}

export function BlockText({ line }: BlockTextProps) {
  return <span className={styles.blockText}>{line}</span>;
}

export function Body({ children }: WrapperProps) {
  return <p className={styles.body}>{children}</p>;
}

export function CalloutWrapper({ children }: WrapperProps) {
  return <div className={styles.callout}>{children}</div>;
}

export function Callout({ text, style }: CalloutProps) {
  return <p className={styles[style]}>{text}</p>;
}

export function CtaWrapper({ children }: WrapperProps) {
  return <div className={styles.ctaWrapper}>{children}</div>;
}

export function Cta({
  children,
  ctaLink,
  color,
  gaHeadline,
  gaCtaText,
  newTab,
}: CtaProps) {
  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  const attributes = linkAttributes(ctaLink, newTab);

  return (
    <a
      onClick={() =>
        HeroGalleryTagManager(gaHeadline ?? "", gaCtaText ?? "", tagPage)
      }
      className={clsx(styles.cta, {
        [styles.ctaPrimary]: color === "Red",
        [styles.ctaSecondary]: color === "White",
        [styles.ctaLoyaltyPrimary]: color === "Loyalty (purple)",
        [styles.ctaLoyaltySecondary]: color === "Loyalty (white)",
      })}
      {...attributes}
    >
      {children}
    </a>
  );
}

export function IconWrapper({ children }: WrapperProps) {
  return <div className={styles.iconWrapper}>{children}</div>;
}

export function BackgroundImages({ imageAttributes }: BackgroundImagesProps) {
  return (
    <>
      <Image
        priority
        src={imageAttributes.mobile.src}
        alt={imageAttributes.mobile.alt}
        fill
        sizes="(max-width: 767px) 100vw, 735px"
        className={clsx(styles.backgroundImage, styles.backgroundImageMobile)}
      />
      <Image
        priority
        src={imageAttributes.tablet.src}
        alt={imageAttributes.tablet.alt}
        fill
        className={clsx(styles.backgroundImage, styles.backgroundImageTablet)}
        sizes="(max-width: 1023px) 100vw, 975px"
      />
      <Image
        priority
        src={imageAttributes.desktop.src}
        alt={imageAttributes.desktop.alt}
        fill
        sizes="(max-width: 1439px) 50vw, 688px"
        className={clsx(styles.backgroundImage, styles.backgroundImageDesktop)}
      />
      <Image
        priority
        src={imageAttributes.wide.src}
        alt={imageAttributes.wide.alt}
        fill
        sizes="688px"
        className={clsx(styles.backgroundImage, styles.backgroundImageWide)}
      />
    </>
  );
}
