import { BrProps } from "@bloomreach/react-sdk";
import { ReactElement } from "react";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { SameDayBanner } from "./SameDayBanner";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return <div>No component or page available</div>;
  }

  const data = getContainerItemContent<SameDayBannerProps>(component, page);

  const props = {
    ...data,
    headerIcon: page?.getContent<ImageSet>(data?.headerIcon)?.getOriginal(),
  };

  return <SameDayBanner data={props} page={page} />;
};
