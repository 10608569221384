import Image from "next/image";
import { useRef, useState, useEffect, useContext } from "react";
import { useResize } from "hooks/useResize";
import { BrRichTextContent } from "../BrRichTextContent";
import { Document } from "@bloomreach/spa-sdk";
import { ImageToUrl } from "services/imageToUrl";
import { PageCheck } from "services/pageCheck";
import { PathContext } from "state_management/Contexts";
import { ImageGalleryTagManager } from "./ImageGalleryTagManager";
import { SingleTabProps, TileComponentProps } from "./types";
import styles from "./Utils.module.scss";
import clsx from "clsx";

export function Tile({
  data,
  page,
  parentTitle,
  listLength,
  clsBtnStyle,
  contentTransition,
  tabSwitch,
  setClsBtnStyle,
  setContentTransition,
  setTabSwitch,
}: TileComponentProps) {
  // States
  const [isActive, setIsActive] = useState(false);
  const [isActiveText, setIsActiveText] = useState(false);
  const [isActiveCloseButton, setIsActiveCloseButton] = useState(false);

  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);

  const size = useResize();
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);
  let labelText = "";

  // Close/Open button animation activated
  useEffect(() => {
    isActive && setIsActiveCloseButton(true);
  }, [isActive]);

  // Reset Tile states on Tab switch
  useEffect(() => {
    if (tabSwitch) {
      setIsActive(false);
      setIsActiveCloseButton(false);
      setIsActiveText(false);
      setTabSwitch(false);
    }
  }, [tabSwitch, setTabSwitch]);

  // Returning animation states after Tab switched
  useEffect(() => {
    if (!isActive && !isActiveCloseButton && !isActiveText && !tabSwitch) {
      setClsBtnStyle({ transition: "transform 0.3s ease" });
      setContentTransition("opacity 0.38s ease");
      setTabSwitch(false);
    }
  }, [
    isActive,
    isActiveCloseButton,
    isActiveText,
    tabSwitch,
    setClsBtnStyle,
    setContentTransition,
    setTabSwitch,
  ]);

  // Truncate label text if it takes more then 2 lines.
  useEffect(() => {
    if (labelRef.current) {
      const element = labelRef.current;
      element.textContent = labelText;

      if (element.offsetHeight > 40) {
        let truncatedText = labelText;
        while (element.offsetHeight > 40 && truncatedText.length > 0) {
          truncatedText = truncatedText.slice(0, -1);
          element.textContent = truncatedText + "...";
        }
      } else {
        element.textContent = labelText;
      }
    }
  }, [labelText, size, tabSwitch]);

  const document = data && page.getContent<Document>(data);

  if (!document) return null;

  const tileData = document.getData();

  const {
    background: { red, green, blue, alpha },
    content,
    faceImage,
    faceLabel,
    textColor: { selectionValues },
    contentHeader,
  } = tileData;

  labelText = faceLabel;

  const contentColor = selectionValues[0].key;
  const coverImageUrl = ImageToUrl(faceImage);

  const coverStyles = {
    backgroundImage: `url(${coverImageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const contentStyles = {
    backgroundColor: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
    transition: contentTransition,
  };

  // Open content on + button click
  const handleActivation = () => {
    setIsActive(true);
    ImageGalleryTagManager(labelText, tagPage, parentTitle);
    setTimeout(() => setIsActiveText(true), 300);
  };

  // Close content on x button click
  const handleDeactivation = () => {
    setIsActive(false);
    setIsActiveCloseButton(false);
    setIsActiveText(false);
  };

  const clickHandle = isActive ? handleDeactivation : handleActivation;

  return (
    <div
      ref={containerRef}
      className={clsx(styles.container, {
        [styles.containerActive]: isActive,
        [styles.containerDuo]: listLength === 2,
      })}
    >
      <section className={styles.cover} style={coverStyles}>
        <div className={styles.label}>
          <div className={styles.labelText} ref={labelRef}>
            {faceLabel}
          </div>
        </div>
      </section>
      <section
        className={clsx(styles.content, {
          [styles.contentWhite]: contentColor === "White",
          [styles.contentDuo]: listLength === 2,
        })}
        style={contentStyles}
      >
        <div className={styles.contentHeader}>
          <p>{contentHeader}</p>
        </div>
        <div
          className={clsx(styles.contentText, {
            [styles.contentTextActive]: isActiveText,
          })}
        >
          {content && (
            <BrRichTextContent page={page!} content={{ html: content.value }} />
          )}
        </div>
      </section>
      <button
        style={clsBtnStyle}
        onClick={clickHandle}
        className={clsx(styles.open, {
          [styles.openActive]: isActiveCloseButton,
        })}
      >
        <div className={styles.openWhite}>
          <Image
            src="/services/printing/static-pages/icons/modal-plus.svg"
            alt=""
            width={18}
            height={18}
            className={styles.openWhiteImg}
          />
        </div>
      </button>
    </div>
  );
}

export const Tab = ({
  tabIndex,
  label,
  activeTabIndex,
  setActiveTabIndex,
  setClsBtnStyle,
  setContentTransition,
  setTabSwitch,
}: SingleTabProps) => {
  const indexHandle = () => {
    setClsBtnStyle({
      transition: "none",
    });
    setContentTransition("none");
    setActiveTabIndex(tabIndex);
    setTabSwitch(true);
  };

  return (
    <button
      onClick={indexHandle}
      className={clsx(styles.tab, {
        [styles.tabActive]: tabIndex === activeTabIndex,
      })}
      data-testid="tab"
    >
      {label}
    </button>
  );
};
