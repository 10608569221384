import Image from "next/legacy/image";
import styles from "./TopNumbers.module.scss";

export const Steps = ({ title, caption, index }: StepsSectionProps) => {
  return (
    <figure className={styles.step} data-testid="numbers-step">
      <div className={styles.stepImgWrapper}>
        <div className={styles.stepImgContainer}>
          <Image
            src={`/services/printing/static-pages/images/numbers/number-${
              index + 1
            }.svg`}
            alt=""
            layout="fill"
            className={styles.stepImage}
          />
        </div>
      </div>
      <figcaption className={styles.stepContent}>
        <div className={styles.stepTitle} data-testid="numbers-step-title">
          <p>{title ? title : "Item title"}</p>
        </div>
        <div className={styles.stepCaption} data-testid="numbers-step-caption">
          <p>{caption ? caption : "Item caption"}</p>
        </div>
      </figcaption>
    </figure>
  );
};
