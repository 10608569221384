import { BrProps } from "@bloomreach/react-sdk";
import { Document, ImageSet, Reference } from "@bloomreach/spa-sdk";
import { Heading, Body, MarketingImage } from "./utils";
import { ReactElement, useEffect, useRef } from "react";
import styles from "./MarketingBlockAnimated.module.scss";
import { imagePlaceholder } from "../../constants";

interface MarketingBlockAnimatedParams {
  document: Reference;
}

export function MarketingBlockAnimated({
  component,
  page,
}: BrProps): ReactElement | null {
  const { document: documentRef } =
    component?.getModels<MarketingBlockAnimatedParams>() ?? {};
  const document = documentRef && page?.getContent<Document>(documentRef);

  const imgWrapperRef = useRef<HTMLDivElement>(null);

  // Transition image when scrolled into view
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle(
          styles.articleImageWrapperShow,
          entry.isIntersecting
        );
      });
    });

    if (imgWrapperRef.current) observer.observe(imgWrapperRef.current);
  }, []);

  if (!document) {
    return page?.isPreview() ? <div>Animated Marketing Block</div> : null;
  }

  const {
    title,
    content,
    image: imageRef,
  } = document.getData<ContentDocument>();
  const image = imageRef && page?.getContent<ImageSet>(imageRef)?.getOriginal();
  const altText =
    imageRef && page?.getContent<ImageSet>(imageRef)?.getDescription();
  const imgSrc = image?.getUrl() ?? imagePlaceholder;
  const htmlContent = content && page?.rewriteLinks(content.value);

  return (
    <article className={styles.article}>
      <div className={styles.articleTextWrapper}>
        {title && <Heading title={title} />}
        {htmlContent && <Body description={htmlContent} />}
      </div>
      <div className={styles.articleImageWrapper} ref={imgWrapperRef}>
        <MarketingImage src={imgSrc} alt={altText} />
      </div>
    </article>
  );
}
