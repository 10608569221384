import React, { useContext, useEffect, useRef, useState } from "react";
import { Document } from "@bloomreach/spa-sdk";
import { BrRichTextContent } from "../BrRichTextContent";
import Image from "next/image";
import { useResize } from "hooks/useResize";
import { ImageToUrl } from "services/imageToUrl";
import { linkAttributes } from "services/linkAttributes";
import { RefHeightCount } from "services/refHeightCount";
import { Divider } from "../utils/Divider";
import { ModalContainer } from "../utils/ModalContainer";
import clsx from "clsx";
import styles from "./RelatedProducts.module.scss";
import {
  DifferentiatorProps,
  ButtonLabelProps,
  TextLabelProps,
  ModalDataProps,
  ModalProps,
  TabContentProps,
  TabProps,
  TabSwitchProps,
  TileComponentProps,
} from "./types";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "services/pageCheck";
import { TileCtaTagManager } from "./RelatedProductsTagManager";

export const Tile = React.memo((props: TileComponentProps) => {
  const {
    data,
    page,
    setDifferentiatorLimit,
    differentiatorLimit,
    updateContentHeight,
    maxContentHeight,
    updateDiffHeight,
    diffMaxHeight,
    listLength,
    openModal,
    setItemData,
    setCurrentItem,
  } = props;

  const [contentStyle, setContentStyle] = useState<React.CSSProperties>({});

  const contentRef = useRef<HTMLDivElement>(null);

  const size = useResize();
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  let initialItem: ModalDataProps;
  let diffLength = 0;

  // Updates content container height by counting heights of children and self paddings and margins
  useEffect(() => {
    if (contentRef.current) {
      const finalHeight = RefHeightCount(contentRef);
      updateContentHeight(finalHeight ?? 0);
    }
  }, [contentRef, size, updateContentHeight]);

  // Sets style for content container
  useEffect(() => {
    if (maxContentHeight) {
      setContentStyle({ height: `${maxContentHeight}px` });
    }
  }, [maxContentHeight, size]);

  // Sets limit of differentiators to align layout (All tiles show equal differentiator qty)
  useEffect(() => {
    if (diffLength < differentiatorLimit) {
      setDifferentiatorLimit(diffLength);
    }
  }, [differentiatorLimit, setDifferentiatorLimit, diffLength]);

  const document = data && page.getContent<Document>(data);
  if (!document) return null;

  const tileData = document.getData();

  // Destructured data from BR
  const { content, differentiatorsArray, foilBadge, tileImage } = tileData;
  const { cardCta, contentLabel, description, title } = content;
  const { address, newTab, title: ctaLabel } = cardCta;
  const { labelText, showLabel, label } = contentLabel;
  const tileImageUrl = ImageToUrl(tileImage);
  const linkProps = linkAttributes(address, newTab);
  diffLength = differentiatorsArray.length;

  const tagManagerHandler = (label: string) => {
    TileCtaTagManager(label, tagPage, title ?? "");
  };

  // Elements

  const TileTop = () => (
    <section className={styles.tileTop}>
      {foilBadge && (
        <div className={styles.tileBadge}>
          <p>Foil Available</p>
        </div>
      )}
      <div className={styles.tileImage}>
        <Image
          src={tileImageUrl ?? ""}
          alt={title}
          fill
          sizes="(min-width: 375px) 75vw, 50vw"
          className={styles.tileImagePic}
        />
      </div>
    </section>
  );

  const TileContent = () => (
    <section
      className={styles.tileContent}
      ref={contentRef}
      style={contentStyle}
    >
      <div className={styles.tileHeader}>
        <h3 className={styles.tileHeaderTitle}>{title}</h3>
        <p className={styles.tileHeaderDescription}>{description}</p>
      </div>
      <div className={styles.tileInfo}>
        {showLabel && (
          <div className={styles.tileLabel}>
            <p className={styles.tileLabelName}>
              {label ? label : "Starting at:"}
            </p>
            <p className={styles.tileLabelText}>{labelText}</p>
          </div>
        )}
        <a
          className={styles.tileCta}
          {...linkProps}
          onClick={() => tagManagerHandler(ctaLabel)}
        >
          {ctaLabel}
        </a>
      </div>
    </section>
  );

  return (
    <article
      className={clsx(styles.tile, {
        [styles.tileDuo]: listLength === 2,
        [styles.tileTrio]: listLength === 3,
      })}
    >
      <TileTop />
      <TileContent />
      <section className={styles.tileBottom}>
        {differentiatorsArray
          .filter(
            (_: ModalDataProps, index: number) =>
              index + 1 <= differentiatorLimit
          )
          .map((item: ModalDataProps, index: number) => {
            initialItem = item;
            const differentiatorProps = {
              data: item,
              updateDiffHeight,
              diffMaxHeight,
              index,
              openModal,
              setItemData,
              setCurrentItem,
            };
            return <Differentiator key={index} {...differentiatorProps} />;
          })}
      </section>
    </article>
  );
});
Tile.displayName = "Tile";
export const Differentiator = (props: DifferentiatorProps) => {
  const {
    data,
    updateDiffHeight,
    diffMaxHeight,
    openModal,
    index,
    setCurrentItem,
    setItemData,
  } = props;
  const [contentStyle, setContentStyle] = useState<React.CSSProperties>({});

  const items = data.differentiatorItemsArray;
  const size = useResize();

  const diffRef = useRef<HTMLDivElement>(null);

  const openHandler = (index: number) => {
    setCurrentItem(index);
    setItemData(data);
    setTimeout(() => {
      openModal();
    }, 100);
  };

  // Updates differentiator containers height
  useEffect(() => {
    if (diffRef.current) {
      const finalHeight = RefHeightCount(diffRef);
      updateDiffHeight(index, finalHeight ?? 0);
    }
  }, [diffRef, size, index, updateDiffHeight]);

  // Sets max height for each level of differentiators
  useEffect(() => {
    if (
      diffMaxHeight.hasOwnProperty(index) &&
      diffMaxHeight[index] !== undefined
    ) {
      setContentStyle({ height: `${diffMaxHeight[index]}px` });
    } else {
      setContentStyle({});
    }
  }, [diffMaxHeight, index, size]);

  const ButtonLabel = ({ iconUrl, label, tabIndex }: ButtonLabelProps) => (
    <button
      className={`${styles.differentiatorLabel} ${styles.differentiatorLabelActive}`}
      onClick={() => openHandler(tabIndex)}
      key={index}
    >
      <div className={styles.differentiatorLabelImage}>
        <Image src={iconUrl ?? ""} alt={label} width={24} height={24} />
      </div>
      <p>{label}</p>
    </button>
  );
  const TextLabel = ({ iconUrl, label }: TextLabelProps) => (
    <div className={styles.differentiatorLabel} key={index}>
      <div className={styles.differentiatorLabelImage}>
        <Image src={iconUrl ?? ""} alt={label} width={24} height={24} />
      </div>
      <p>{label}</p>
    </div>
  );

  return (
    <div
      className={styles.differentiator}
      ref={diffRef}
      style={contentStyle}
      data-testid="differentiator"
    >
      <Divider marginVar={16} />
      <p className={styles.differentiatorHeader}>{data.sectionHeader}</p>
      <div className={styles.differentiatorLabelContainer}>
        {items.map((item, index: number) => {
          const { icon, label, modalContent } = item;
          const iconUrl = ImageToUrl(icon);

          return (
            <div key={index}>
              {modalContent.value === "" ? (
                <TextLabel iconUrl={iconUrl ?? ""} label={label} />
              ) : (
                <ButtonLabel
                  iconUrl={iconUrl ?? ""}
                  label={label}
                  tabIndex={index}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const Modal = ({
  data,
  page,
  modalIsOpen,
  wrapperIsOpen,
  closeModal,
  currentItem,
  setCurrentItem,
}: ModalProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  if (!data) return null;
  const { modalHeader, differentiatorItemsArray } = data;

  const items = differentiatorItemsArray;

  const containerProps = {
    closeModal,
    wrapperIsOpen,
    modalIsOpen,
    wrapperRef,
  };

  const Content = () => (
    <div className={styles.modalContainer}>
      <div className={styles.modalHeader}>
        <h3>{modalHeader}</h3>
      </div>

      <Tabs
        data={items}
        page={page}
        wrapperIsOpen={wrapperIsOpen}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
      />
    </div>
  );

  return (
    <ModalContainer {...containerProps}>
      <div
        ref={wrapperRef}
        className={clsx(styles.modal, {
          [styles.modalFixed]: items.length > 1,
        })}
      >
        <Content />
      </div>
    </ModalContainer>
  );
};

export const Tabs = ({
  data,
  page,
  wrapperIsOpen,
  currentItem,
  setCurrentItem,
}: TabProps) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const currentData = data.filter((_, index) => index === currentItem)[0];

  const filteredData = data.filter((item) => item.modalContent.value !== "");

  // Elements
  const TabSwitch = ({ index, tabLabel, label }: TabSwitchProps) => (
    <div key={index}>
      <button
        className={clsx(styles.tabsItem)}
        onClick={() => setCurrentItem(index)}
        onMouseEnter={() => setHoveredIndex(index)} // Set hovered index
        onMouseLeave={() => setHoveredIndex(null)} // Reset hovered index
      >
        <p>{tabLabel ? tabLabel : label}</p>
      </button>
      <div
        className={clsx(styles.selector, {
          [styles.selectorActive]: index === currentItem && wrapperIsOpen,
          [styles.selectorHovered]:
            index === hoveredIndex && index !== currentItem, // Apply hover style conditionally
        })}
      />
    </div>
  );

  const TabsPanel = () => {
    const filteredData = data.filter((item) => item.modalContent.value !== "");
    return (
      <div className={styles.tabsTop}>
        <div className={styles.tabsSwitches}>
          {filteredData.map((item, index) => {
            const { tabLabel, label } = item;
            return (
              <TabSwitch
                index={index}
                tabLabel={tabLabel}
                label={label}
                key={index}
              />
            );
          })}
        </div>
        <Divider marginVar={0} />
      </div>
    );
  };

  const TabContent = ({ data, page }: TabContentProps) => {
    const { modalContent: content } = data;

    return (
      <div className={styles.modalContent}>
        {content && (
          <BrRichTextContent page={page!} content={{ html: content.value }} />
        )}
      </div>
    );
  };

  return (
    <section className={styles.tabs}>
      {filteredData.length > 1 && <TabsPanel />}
      <TabContent data={currentData} page={page} />
    </section>
  );
};
