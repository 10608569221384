import { ImageSet } from "@bloomreach/spa-sdk";
import Image from "next/legacy/image";
import styles from "./IndustryTiles.module.scss";
import { imagePlaceholder } from "../../constants";

export const Tile = ({ data }: IndustrySingleTileProps) => {
  const { link, foil, image, title } = data;
  return (
    <a href={link ? link : "#"}>
      <div className={styles.tile}>
        {foil && (
          <div className={styles.badge} data-testid="tile-foil">
            <p>Foil Available</p>
          </div>
        )}
        <figure>
          <div className={styles.image}>
            <Image
              src={image ? image.getUrl() : imagePlaceholder}
              alt=""
              layout="fill"
              objectFit="cover"
              className={styles.imageImg}
            />
          </div>

          <figcaption className={styles.title}>
            <h2>{title ? title : "Title"}</h2>
          </figcaption>
        </figure>
      </div>
    </a>
  );
};
