import { ImageSet } from "@bloomreach/spa-sdk";
import styles from "./LongProductTile.module.scss";
import Image from "next/legacy/image";
import { imagePlaceholder } from "../../constants";

export const Tile = ({ data }: LPTSingleProps) => {
  const { title, image, link } = data;
  return (
    <a href={link ? link : "#"} className={styles.card}>
      <figure className={styles.cardFigure}>
        <Image
          src={image ? image.getUrl() : imagePlaceholder}
          alt=""
          layout="fill"
          objectFit="cover"
          className={styles.cardImg}
        />
        <figcaption>
          <div className={styles.cardTitle}>
            <h2>{title ? title.toUpperCase() : `Card Title`.toUpperCase()}</h2>
          </div>
        </figcaption>
      </figure>
    </a>
  );
};
