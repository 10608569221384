import { ReactElement } from "react";
import { ImageSet } from "@bloomreach/spa-sdk";
import { CarouselTileCMS } from "./CarouselTileCMS";

export const Index = ({
  page,
  tile,
}: CarouselTileCmsProps): ReactElement | null => {
  // CMS Data
  const {
    title,
    caption,
    link,
    image,
    badge,
    startingAt,
    promo,
    sameDay,
    optionText,
  } = tile;
  const cardImage = image && page.getContent<ImageSet>(image)?.getOriginal();

  return (
    <CarouselTileCMS
      title={title}
      caption={caption}
      link={link}
      image={cardImage}
      badge={badge}
      startingAt={startingAt}
      promo={promo}
      sameDay={sameDay}
      optionText={optionText}
      page={page}
    />
  );
};
