import { ReactElement } from "react";
import { MarketingLandingBanner } from "./MarketingLandingBanner";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { title, image, buttons, breadcrumbsDynamic, pmBreadCrumbs } =
    getContainerItemContent<MarketingHeroBannerProps>(component, page) ?? {};

  let bgImage = image && page?.getContent<ImageSet>(image)?.getOriginal();

  return (
    <MarketingLandingBanner
      breadcrumbs={breadcrumbsDynamic}
      title={title}
      buttons={buttons}
      image={bgImage}
      page={page}
      manualBc={pmBreadCrumbs?.pmAddBreadcrumb}
    />
  );
};
