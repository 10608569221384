import { CUSTOM_SEARCH_ROUTES } from "./custom-search-routes";
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import axios from "axios";
import clsx from "clsx";
import debounce from "lodash.debounce";
import styles from "./SearchBarNew.module.scss";
import useSWR from "swr";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import CircleXmark from "@/public/static-pages/icons/navbar/circle-xmark.svg";
import SearchIcon from "@/public/static-pages/icons/navbar/search.svg";

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export function SearchBar({ badge }: { badge?: string }) {
  const DEFAULT_FORM_ACTION = "/services/printing/Search";

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [isFormActive, setIsFormActive] = useState(false);
  const [searchTokenValue, setSearchTokenValue] = useState(badge);
  const [searchTerm, setSearchTerm] = useState<string>();

  const prevData = useRef([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLUListElement>(null);
  const suggestionIndex = useRef(0);
  const redirectUrl = useRef("");

  const { data: suggestions } = useSWR(
    () =>
      searchTerm
        ? `${process.env.NEXT_PUBLIC_BASEPATH}/static-pages/api/suggestions?value=${searchTerm}`
        : null,
    fetcher
  );

  // Get a new searchParams string by merging the current
  // searchParams with a provided key/value pair
  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams?.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const handleChange = useCallback(() => {
    if (searchTokenValue === undefined) setSearchTerm(inputRef.current?.value);
  }, [searchTokenValue]);

  const debouncedHandleChange = useMemo(
    () => debounce(handleChange, 50),
    [handleChange]
  );

  useEffect(() => {
    if (suggestions?.length > 0) prevData.current = suggestions;
    if (!inputRef.current?.value) prevData.current = [];
  }, [suggestions, inputRef.current?.value]);

  const suggestionsList = (data: Array<AutocompleteSuggestion>) => (
    <ul
      className={clsx({
        [styles.formAutocomplete]: data.length > 0,
      })}
      ref={suggestionsRef}
    >
      {data.map((suggestion: AutocompleteSuggestion, index) => (
        <li
          className={styles.formAutocompleteSuggestion}
          key={index}
          onMouseEnter={() => handleMouseEnter(suggestion.name, suggestion.key)}
          onMouseDown={() => handleMouseDown(suggestion.key)}
        >
          {suggestion.name}
          {suggestion.parentCategoryId &&
            ` - ${suggestion.parentCategoryId
              .split("/")[0]
              .replace(/-/gi, " ")}`}
        </li>
      ))}
    </ul>
  );

  function handleFocus() {
    setIsFormActive(true);
  }

  function handleBlur() {
    setIsFormActive(false);
    handleExit();
  }

  function handleExit() {
    setSearchTerm(undefined);
    prevData.current = [];
    suggestionIndex.current = 0;
  }

  function handleRemoveToken() {
    setSearchTokenValue(undefined);
  }

  function handleMouseEnter(categoryName: string, categoryKey: string) {
    if (inputRef.current) inputRef.current.value = categoryName;
    redirectUrl.current = categoryKey;
  }

  function handleMouseDown(categoryKey: string) {
    window.location.href = `/services/printing/${categoryKey.toLowerCase()}`;
  }

  // Keyboard controls
  function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    const suggestionsLength = suggestionsRef.current?.childNodes.length;
    const activeClassName = styles.formAutocompleteSuggestionActive;
    const activeSuggestion = suggestionsRef.current?.querySelector(
      `.${activeClassName}`
    );

    function toggleClassName(index: number, method: "add" | "remove") {
      if (method === "add") {
        suggestionsRef.current?.children[index].classList.add(activeClassName);
      } else {
        suggestionsRef.current?.children[index].classList.remove(
          activeClassName
        );
      }
      suggestionIndex.current = index;
      setInputValues();
    }

    function setInputValues() {
      if (inputRef.current)
        inputRef.current.value = suggestions[suggestionIndex.current].name;
      redirectUrl.current = suggestions[suggestionIndex.current].key;
    }

    if (event.code === "Escape" || event.code === "Tab") {
      handleExit();
      return;
    }

    if (event.code === "Enter") {
      // Redirects to category returned by search API
      if (redirectUrl.current) {
        event.preventDefault();
        window.location.href = `/services/printing/${redirectUrl.current}`;
      }

      const sanitizedTerm =
        inputRef.current?.value.trim().toLocaleLowerCase() ?? "";

      // Update search param on Template pages
      if (searchTokenValue) {
        event.preventDefault();
        router.push(
          pathname + "?" + createQueryString("search", sanitizedTerm)
        );
        return;
      }

      // Redirects to route found in CUSTOM_SEARCH_ROUTES data
      if (sanitizedTerm in CUSTOM_SEARCH_ROUTES) {
        event.preventDefault();
        window.location.href = `/services/printing/${CUSTOM_SEARCH_ROUTES[sanitizedTerm]}`;
      }

      if (sanitizedTerm === "") {
        event.preventDefault();
        return;
      }
    }

    if (event.code === "ArrowDown") {
      if (!activeSuggestion && suggestionsLength) {
        toggleClassName(suggestionIndex.current, "add");
      }

      if (activeSuggestion && suggestionsLength) {
        toggleClassName(suggestionIndex.current, "remove");
        if (suggestionIndex.current === suggestionsLength - 1) {
          toggleClassName(0, "add");
        } else {
          toggleClassName(suggestionIndex.current + 1, "add");
        }
      }
      return;
    }

    if (event.code === "ArrowUp") {
      event.preventDefault();

      if (!activeSuggestion && suggestionsLength) {
        toggleClassName(suggestionsLength - 1, "add");
      }

      if (activeSuggestion && suggestionsLength) {
        toggleClassName(suggestionIndex.current, "remove");
        if (suggestionIndex.current === 0) {
          toggleClassName(suggestionsLength - 1, "add");
        } else {
          toggleClassName(suggestionIndex.current - 1, "add");
        }
      }
      return;
    }

    if (event.code !== "ArrowDown" && event.code !== "ArrowUp") {
      redirectUrl.current = "";

      if (activeSuggestion && suggestionsLength) {
        toggleClassName(suggestionIndex.current, "remove");
        suggestionIndex.current = 0;
      }
    }
  }

  return (
    <form
      role="search"
      className={clsx(styles.form, {
        [styles.formActive]: isFormActive || searchTokenValue,
      })}
      action={DEFAULT_FORM_ACTION}
      method="get"
    >
      <div className={styles.formContainer}>
        {searchTokenValue && (
          <span className={styles.formToken} data-pniautomationid="SearchTerm">
            <span className={styles.formTokenTerm}>{searchTokenValue}</span>
            <button
              type="button"
              className={styles.formTokenClear}
              aria-label="Remove token"
              onClick={handleRemoveToken}
            >
              <CircleXmark />
            </button>
          </span>
        )}

        <label
          htmlFor="site-search"
          className={styles.formTitle}
          aria-label="Search"
        >
          <SearchIcon />
        </label>
        <input
          type="text"
          name="search"
          defaultValue={searchParams?.get("search") ?? undefined}
          autoComplete="off"
          id="site-search"
          className={clsx("scTrack scInput", styles.formField)}
          placeholder="Search"
          maxLength={60}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={debouncedHandleChange}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          data-sctype="scInput"
          data-scvalue="search"
          data-scinput="input"
          data-scsticky="true"
          data-pniautomationid="SearchBar"
        />
      </div>
      {suggestions?.length > 0 && suggestionsList(suggestions)}
      {suggestions === undefined &&
        inputRef.current?.value &&
        suggestionsList(prevData.current)}
    </form>
  );
}
