import { getContainerItemContent, Page } from "@bloomreach/spa-sdk";
import axios from "axios";

import { useContext, useEffect, useMemo, useState } from "react";
import { PathContext } from "state_management/Contexts";
import useSWR from "swr";

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

interface BcListProps {
  key: string | undefined;
  value: string | undefined;
  flag: string | undefined;
}
interface DocumentsProps {
  link: string | undefined;
  title: string | null;
}
interface ComponentProps {
  changedTitle: string;
}

export const useBreadcrumbExternal = (
  banner: boolean,
  component: any,
  manualBreadcrumbs: { $ref: string | null }[] | undefined,
  page: Page | undefined
) => {
  const path = useContext(PathContext);

  const { changedTitle } = component?.getParameters();

  const { data } = useSWR(
    process.env.NEXT_PUBLIC_BASEPATH + "/static-pages/api/breadcrumbs",
    fetcher
  );

  const [configPath, setConfigPath] = useState<string>("");
  const [manualBc, setManualBc] = useState<{ $ref: string | null }[]>([]);
  const [pagesList, setPagesList] = useState<ListDataProps[]>([]);
  const [bcPaths, setBcPaths] = useState<string[]>([]);

  const [addedBcList, setAddedBcList] = useState<BcListProps[]>([]);
  const [bcMod, setBcMod] = useState<ListDataProps[]>([]);

  const [bc, setBc] = useState<ListDataProps[]>([]);

  // Functions

  // Clears link from tokens and other information to work with the list
  const clearedLink = (link: string | undefined) => {
    if (link === undefined) {
      return;
    }
    for (let i = 0; i < link?.length; i++) {
      if (link[i + 1] === "?" || i === link.length - 1) {
        return process.env.NEXT_PUBLIC_BASEPATH + link.slice(0, i + 1);
      }
    }
  };

  //Comparing paths for initial page hierarchy with the existing list of pages. Creating list of breadcrumbs (path, name)
  function createBreadCrumbsList(
    pathsList: string[],
    pagesList: ListDataProps[]
  ) {
    const breadCrumbsList: ListDataProps[] = [];

    pathsList.forEach((path, index) => {
      const listPath = path.replace(/\//g, "");
      pagesList.forEach((item) => {
        if (
          item.key === listPath &&
          listPath !== "home" &&
          listPath !== "servicesprinting" &&
          path
        ) {
          index === pathsList.length - 1 && changedTitle !== ""
            ? breadCrumbsList.push({
                key: path,
                value: changedTitle,
                flag: "int",
              })
            : breadCrumbsList.push({
                key: path,
                value: item.value,
                flag: "int",
              });
        }
      });
    });
    return breadCrumbsList;
  }

  // Triggers

  // Checking existing list of pages
  useEffect(() => {
    if (data !== undefined) {
      setPagesList(data.data.valueSets[0].messages);
    }
  }, [data]);

  // Checking current pathway
  useMemo(() => {
    if (path.path) setConfigPath(path.path);
  }, [path]);

  // Setting
  useEffect(() => {
    let bcList = null;
    if (!banner && page !== undefined) {
      const document = getContainerItemContent<any>(component, page) ?? {};

      bcList = document?.manualBreadcrumbs;
    } else {
      bcList = manualBreadcrumbs;
    }
    setManualBc(bcList);
  }, [banner, manualBreadcrumbs, component, page]);

  // Dividing page's path to the list of hierarchical paths
  useEffect(() => {
    if (configPath !== "" && configPath.length > 1) {
      let paths = [];
      let config = configPath;
      // Add this code if /services/printing/static is not a basepath anymore

      // if (configPath.includes("/services/printing/static")) {
      //   const prefix = "/services/printing/static";
      //   const prefixLength = prefix.length;
      //   config = configPath.slice(prefixLength);
      // }
      for (let i = 0; i < config.length; i++) {
        if (i > 0 && config[i + 1] === "/") {
          paths.push(config.slice(0, i + 1));
        }
      }
      setBcPaths(paths);
    }
  }, [configPath]);

  // Determining list of initial breadcrumbs
  useEffect(() => {
    if (bcPaths.length) {
      const breadcrumbs = createBreadCrumbsList(bcPaths, pagesList);
      setBc(breadcrumbs);
    }
  }, [bcPaths, pagesList]);

  // Determining list of manual breadcrumbs
  useEffect(() => {
    if (!manualBc?.length || page === undefined) return;

    // Creating the list of manual pages links
    const documents: DocumentsProps[] = [];
    manualBc?.forEach((bc: any) => {
      if (bc === null) return;
      if (bc.contentType === "brxsaas:pmAddInternalBreadcrumb") {
        const docLink = bc.cmsBc && page?.getContent<Document>(bc.cmsBc);
        documents.push({
          link: clearedLink(docLink?.getUrl()),
          title: null,
        });
      } else {
        documents.push({
          link: bc.externalBc,
          title: bc.title,
        });
      }
    });

    // Creating a list of path/name pairs for additional breadcrumbs
    const addonList: BcListProps[] = [];
    if (!documents.length) return;

    documents.forEach((path) => {
      if (path?.title === null) {
        const listPath = path?.link?.replace(/\//g, "");
        pagesList.forEach((item) => {
          if (item.key === listPath && path.link !== undefined) {
            addonList.push({
              key: path?.link,
              value: item?.value,
              flag: "int",
            });
          }
        });
      } else {
        addonList.push({
          key: path?.link,
          value: path?.title,
          flag: "ext",
        });
      }
    });
    setAddedBcList(addonList);
  }, [manualBc, pagesList, page]);

  useEffect(() => {
    if (!addedBcList.length || !bc.length) return;

    const addedBreadCrumbs = [...addedBcList, bc[bc.length - 1]];
    setBcMod(addedBreadCrumbs);
  }, [addedBcList, bc]);

  return bcMod.length ? bcMod : bc;
};
