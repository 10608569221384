import { ReactElement } from "react";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { ValueProps } from "./ValueProps";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { title, card, animated, lifted, shape, iconDimensions } =
    getContainerItemContent<ValueCmsProps>(component, page) ?? {};

  const cardsArray: ValueCardProps[] = [];

  card?.forEach((card) => {
    const { icon, title, caption, ctaText, ctaLink, newTab } = card;
    const imageUrl = icon && page?.getContent<ImageSet>(icon)?.getOriginal();

    cardsArray.push({
      icon: imageUrl,
      title: title,
      caption: caption,
      ctaText: ctaText,
      ctaLink: ctaLink,
      newTab: newTab,
    });
  });

  const shapeVar = shape?.selectionValues[0].key;

  const { anchor } = component.getParameters<AnchorId>();

  return (
    <ValueProps
      title={title}
      cards={cardsArray}
      animated={animated}
      lifted={lifted}
      shape={shapeVar}
      iconDimensions={iconDimensions}
      id={anchor}
    />
  );
};
