import { useState, useRef } from "react";
import { ImageSet, Page, Reference } from "@bloomreach/spa-sdk";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import styles from "./ScrollableTiles.module.scss";
import { useDraggable } from "hooks/useDraggable";
import { useResize } from "hooks/useResize";
import { Tile } from "./ScrollableTilesUtils";

interface ScrollableTilesProps {
  containerData: {
    headlineTitle: string | undefined;
    pmaccessoriestile:
      | {
          title: string;
          caption: string;
          tileImage: Reference;
        }[]
      | undefined;
  };
  page: Page;
}

export function ScrollableTiles({ containerData, page }: ScrollableTilesProps) {
  // Gradient overlays control
  const [leftOverlayVisible, setLeftOverlayVisible] = useState(false);
  const [rightOverlayVisible, setRightOverlayVisible] = useState(true);
  const { headlineTitle, pmaccessoriestile } = containerData;
  // Misc
  const containerRef = useRef<HTMLUListElement>(null);
  const size = useResize();
  //Sets visibility of overlays
  function handleScroll(e: React.UIEvent) {
    const { scrollWidth, clientWidth, scrollLeft } = e.target as HTMLDivElement;
    const maxScrollLeft = scrollWidth - clientWidth;
    setLeftOverlayVisible(scrollLeft > 0);
    setRightOverlayVisible(Math.ceil(scrollLeft) < maxScrollLeft);
  }
  //  Make container draggable
  const handleMouseDown = useDraggable(containerRef);

  return (
    <article className={styles.component}>
      <h2
        className={clsx(styles.componentTitle, {
          [styles.componentTitlePlaceholder]: !headlineTitle,
        })}
      >
        {headlineTitle ? headlineTitle : "Add Component Title"}
      </h2>

      <div className={styles.componentScrollAreaWrapper}>
        {size >= 768 && (
          <>
            <div
              className={clsx(styles.componentLeftOverlay, {
                [styles.componentLeftOverlayVisible]: leftOverlayVisible,
              })}
            />
            <div
              className={clsx(styles.componentRightOverlay, {
                [styles.componentRightOverlayVisible]: rightOverlayVisible,
              })}
            />
          </>
        )}
        <ul
          className={styles.componentScrollArea}
          ref={containerRef}
          onMouseDown={handleMouseDown}
          onScroll={handleScroll}
        >
          {pmaccessoriestile?.map((tile) => {
            const tileData = {
              ...tile,
              tileImage:
                page
                  .getContent<ImageSet>(tile?.tileImage)
                  ?.getOriginal()
                  ?.getUrl() ?? "",
            };
            return <Tile tileData={tileData} key={uuidv4()} />;
          })}
        </ul>
      </div>
    </article>
  );
}
