import { useContext } from "react";
import { BrRichTextContent } from "../BrRichTextContent";
import Image from "next/image";
import { useModal } from "hooks/useModal";
import { ImageToUrl } from "services/imageToUrl";
import { Modal } from "./Utils";
import { ClipboardButton } from "../utils/ClipboardButton";
import { ElementsProps } from "./types";
import styles from "./PromoRibbon.module.scss";
import { PageCheck } from "services/pageCheck";
import { PathContext } from "state_management/Contexts";

export const Container = ({ page, data }: ElementsProps) => {
  const { code, description, modalHeader, disclaimer, icon, useModalCode } =
    data;

  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  const TagManagerProps = {
    tagPage: tagPage,
    taggedComponent: "PromoRibbon",
    taggedElement: "PromoRibbon main container",
  };

  // Modal basic functions
  const { modalIsOpen, wrapperIsOpen, openModal, closeModal } = useModal();

  const iconUrl = icon && ImageToUrl(icon);

  // Props
  const modalProps = {
    closeModal,
    wrapperIsOpen,
    disclaimer,
    modalIsOpen,
    code,
    page,
    modalHeader,
    useModalCode,
    tagPage,
  };

  return (
    <article className={styles.container} data-testid="ribbon-container">
      <div className={styles.content}>
        <div className={styles.containerIcon}>
          <Image src={iconUrl ?? ""} alt="" width={20} height={20} />
        </div>
        <div className={styles.description}>
          {description && (
            <BrRichTextContent
              page={page}
              content={{ html: description.value }}
            />
          )}

          {code && (
            <div className={styles.clipboardButtonWrapper}>
              <ClipboardButton code={code} {...TagManagerProps}>
                <span className={styles.descriptionButtonText}>{code}</span>
                <div className={styles.copyIcon}>
                  <Image
                    src="/services/printing/static-pages/icons/copy.svg"
                    alt=""
                    width={10}
                    height={10}
                  />
                </div>
              </ClipboardButton>
            </div>
          )}
        </div>
      </div>
      {disclaimer && (
        <button className={styles.detailsButton} onClick={openModal}>
          <p>Details</p>
        </button>
      )}
      <Modal {...modalProps} />
    </article>
  );
};
