import { Section } from "./Section";
import styles from "./TopIcons.module.scss";
import { v4 as uuidv4 } from "uuid";

export const TopIcons = ({ data, page }: TopIconsProps) => {
  const { title, card, iconDimensions } = data;

  return (
    <article className={styles.container}>
      <h2 className={styles.title} data-testid="value-title">
        {title}
      </h2>
      <section data-testid="value-props-content" className={styles.content}>
        {card?.map((tile) => {
          return (
            <Section
              key={uuidv4()}
              data={tile}
              page={page}
              iconDimensions={iconDimensions}
            />
          );
        })}
      </section>
    </article>
  );
};
