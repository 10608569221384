import { BrRichTextContent } from "../BrRichTextContent";
import styles from "./PrintSolutionsLayout.module.scss";
import Image from "next/legacy/image";
import { imagePlaceholder } from "../../constants";

export const Tile = ({ data, page }: PrintSolutionFCProps) => {
  const { title, pagePath, imagePath, caption } = data;

  return (
    <a
      href={
        pagePath ? process.env.NEXT_PUBLIC_BASEPATH + pagePath.getUrl() : "#"
      }
    >
      <div className={styles.tile}>
        <figure>
          <div className={styles.image}>
            <Image
              src={imagePath ? imagePath?.getUrl() : imagePlaceholder}
              alt=""
              layout="fill"
              objectFit="cover"
              className={styles.imageImg}
            />
          </div>

          <figcaption className={styles.text}>
            <h2>{title ? title : "Title"}</h2>
            <div className={styles.caption} data-testid="caption">
              {caption && (
                <BrRichTextContent
                  page={page!}
                  content={{ html: caption?.value }}
                />
              )}
            </div>
          </figcaption>
        </figure>
      </div>
    </a>
  );
};
