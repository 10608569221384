import { ReactElement } from "react";
import { ContainerItem, getContainerItemContent } from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";

import { PricingGrid } from "./PricingGrid";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { componentTitle, caption, pmPricingTable } =
    getContainerItemContent<PricingGridProps>(component, page) ?? {};

  return (
    <PricingGrid
      componentTitle={componentTitle}
      pmPricingTable={pmPricingTable ?? []}
      caption={caption}
      page={page}
    />
  );
};
