import { useEffect, useState } from "react";
import useSWR from "swr";
import axios from "axios";

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export const useMeta = (
  path: string,
  pmMeta: PmMetaProps,
  context: ContextProps,
  bodyDataPair: any,
  bodyDataTitle: any
) => {
  const [pathToUse, setPathToUse] = useState<string | undefined>(path);
  const [listLink, setListLink] = useState("home");
  const [metaTitle, setMetaTitle] = useState("Home Page");
  const [pairToLoad, setPairToLoad] = useState({
    key: "",
    value: "",
  });
  const [titleToLoad, setTitleToLoad] = useState<ListKeyValueProps[]>([]);
  const [trigger, setTrigger] = useState(false);

  const { data } = useSWR(
    process.env.NEXT_PUBLIC_BASEPATH + "/static-pages/api/breadcrumbs",
    fetcher
  );

  // Setting correct paths to compare with the list if in development mode
  useEffect(() => {
    if (path) {
      const pathToUse = path.split("?")[0];
      setPathToUse(pathToUse);
    }
  }, [path]);

  // Deleting "/" to place to the list
  useEffect(() => {
    if (context && context.preview) {
      setTrigger(true);
    }
  }, [context]);

  useEffect(() => {
    if (pathToUse && pathToUse !== "/" && trigger) {
      const listLink = pathToUse.replace(/\//g, "");
      setListLink(listLink);
    }
  }, [pathToUse, trigger]);

  // Checking page title from SMC SEO
  useEffect(() => {
    if (pmMeta && trigger) {
      setMetaTitle(pmMeta.title);
    }
  }, [pmMeta, trigger]);

  // Comparing data with the list
  useEffect(() => {
    if (data && trigger) {
      const listData: ListDataProps[] = data.data.valueSets[0].messages;
      const listMatches = listData.filter((match) => match.key === listLink);

      if (!listMatches.length && metaTitle !== "Home Page") {
        setPairToLoad({ key: listLink, value: metaTitle });
      } else if (
        listMatches.length &&
        listMatches[0].value !== metaTitle &&
        metaTitle !== "Home Page"
      ) {
        const messages: ListKeyValueProps[] = [
          { key: listLink, value: metaTitle },
        ];
        listData
          .filter((listPair) => listPair.key !== listMatches[0].key)
          .forEach((msg) => messages.push(msg));
        setTitleToLoad(messages);
      }
    }
  }, [data, metaTitle, listLink, trigger]);

  // PUT Request to add key/value to the list
  useEffect(() => {
    if (pairToLoad.key && data) {
      const listData = data.data.valueSets[0].messages;
      const bodyData = bodyDataPair(data, pairToLoad, listData);

      fetch(process.env.NEXT_PUBLIC_BASEPATH + `/api/meta`, {
        method: "PUT",
        headers: {
          "x-resource-version": data.version,
        },
        body: JSON.stringify(bodyData),
      }).then((res) => res);
    }
  }, [pairToLoad, data, bodyDataPair]);

  // PUT request to change value (If SEO Title is changed)
  useEffect(() => {
    if (titleToLoad.length && data) {
      const bodyData = bodyDataTitle(data, titleToLoad);

      fetch(process.env.NEXT_PUBLIC_BASEPATH + `/api/meta`, {
        method: "PUT",
        headers: {
          "x-resource-version": data.version,
        },
        body: JSON.stringify(bodyData),
      }).then((res) => res);
      setTitleToLoad([]);
    }
  }, [titleToLoad, bodyDataTitle, data]);
};
