import Image from "next/legacy/image";
import { useEffect, useRef, useContext } from "react";
import styles from "./Masonry.module.scss";
import { MasonryTagManager } from "./MasonryTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { imagePlaceholder } from "../../constants";

export const MasonryTile = ({
  link,
  image,
  title,
  index,
  windowSize,
  sectionTitle,
}: MasonryTileProps) => {
  const figureRef = useRef<HTMLElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const page = PageCheck(path);
  // End GA tagging

  useEffect(() => {
    const cardRatio = (
      indexZero: string,
      indexOneFour: string,
      indexElse: string
    ) => {
      if (index === 0) {
        return indexZero;
      } else if (index === 1 || index === 2 || index === 3 || index === 4) {
        return indexOneFour;
      } else {
        return indexElse;
      }
    };

    let imageSize = cardRatio("88%", "75%", "80%");
    let titleSize = cardRatio("12%", "25%", "20%");

    if (windowSize >= 1024) {
      if (figureRef.current !== null) {
        figureRef.current.style.height = imageSize;
      }
      if (titleRef.current !== null) {
        titleRef.current.style.height = titleSize;
      }
    }
  }, [index, windowSize]);

  return (
    <a
      href={link ? link : "#"}
      className={styles.card}
      onClick={() => MasonryTagManager(sectionTitle, title, page)}
    >
      <figure className={styles.cardFigure} ref={figureRef}>
        <Image
          src={image ? image : imagePlaceholder}
          alt={title}
          layout="fill"
          className={styles.cardImg}
          objectFit="cover"
        />
      </figure>
      <div className={styles.cardTitle} ref={titleRef}>
        <p>{title ? title : `Card ${index + 1} Title`}</p>
      </div>
    </a>
  );
};
