import { DocumentProductTiles } from "./DocumentProductTiles";
import { BrProps } from "@bloomreach/react-sdk";
import React, { ReactElement } from "react";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { title, tiles } =
    getContainerItemContent<DocumentPrintTilesProps>(component, page) ?? {};

  const tileArray: DocumentPrintTileProp[] = [];

  tiles?.forEach((card) => {
    const { title, subtitle, description, image, link, button } = card;

    const imageUrl = image && page?.getContent<ImageSet>(image)?.getOriginal();

    tileArray.push({
      title: title,
      subtitle: subtitle,
      description: description,
      imageUrl: imageUrl,
      button: button,
      link: link,
    });
  });

  return <DocumentProductTiles title={title} tiles={tileArray} page={page} />;
};
