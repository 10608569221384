import { BrProps } from "@bloomreach/react-sdk";
import { ContainerItem, getContainerItemContent } from "@bloomreach/spa-sdk";
// Activate when Product API ready
// import { Index as CarouselTile } from "../CarouselTile";
import { Index as CarouselTileCMS } from "../CarouselTileCMS";
import {
  useRef,
  createRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { v4 as uuidv4 } from "uuid";
import animateScrollTo from "animated-scroll-to";
import clsx from "clsx";
import styles from "./ProductCarousel.module.scss";
import { CarouselTagManager } from "./CarouselTagManager";
import useSWR from "swr/immutable";
import axios from "axios";
import { MetaFlagsContext, PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { Headline } from "@/components/utils/Headline";

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export function ProductCarousel({ component, page }: BrProps<ContainerItem>) {
  // Extract heading and tiles only if component and page are defined
  const content =
    component && page
      ? getContainerItemContent<ProductCarouselCMSContent>(component, page)
      : undefined;
  const heading = content?.heading;
  const tiles = content?.tiles;

  // Extract anchor if component defined
  const parameters = component
    ? component.getParameters<AnchorId>()
    : undefined;
  const anchor = parameters?.anchor;

  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const flags = useContext(MetaFlagsContext);
  const tagPage = PageCheck(path);
  // End GA tagging

  // Activate when Product API is ready
  // const { heading, tiles } =
  //   getContainerItemContent<ProductCarouselContent>(component, page) ?? {};
  // const { data } = useSWR(`api/product-api/`, fetcher);

  const screens = {
    smallDesktop: 960,
  } as const;

  // Carousel tile and pagination counts
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [slideCount, setSlideCount] = useState<number>(0);

  // Carousel UI initialized
  const [isInit, setIsInit] = useState(false);

  // Refs for manipulating the DOM
  const hasPaginationTarget = useRef(false);
  const trackRef = useRef<HTMLUListElement | null>(null);
  const previousRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const [slideRefs, setSlideRefs] = useState<React.RefObject<HTMLLIElement>[]>(
    []
  );
  const [paginationRefs, setPaginationRefs] = useState<
    React.RefObject<HTMLButtonElement>[]
  >([]);

  // Get number of tiles in carousel
  const getTileCount = useCallback(() => {
    if (tiles?.length) return tiles.length;
    return 1;
  }, [tiles]);

  // Scroll to begining of carousel when number of pages updates
  useEffect(() => {
    trackRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [paginationCount]);

  // Set carousel tile count based on CMS data
  useEffect(() => {
    setSlideCount(getTileCount());
  }, [getTileCount]);

  // Create refs for carousel tiles and pagination buttons
  useEffect(() => {
    setSlideRefs([...Array(slideCount)].map(() => createRef<HTMLLIElement>()));
    setPaginationRefs(
      [...Array(slideCount)].map(() => createRef<HTMLButtonElement>())
    );
  }, [slideCount]);

  // Initialize carousel when number of tiles and pages are set
  useEffect(() => {
    if (slideRefs.length && paginationRefs.length) {
      setIsInit(true);

      const trackWidth = trackRef.current?.clientWidth;
      const slideWidth = slideRefs[0].current?.clientWidth;

      if (trackWidth && slideWidth) {
        const slidesInView = Math.floor(trackWidth / slideWidth);
        const count = Math.ceil(getTileCount() / slidesInView);
        setPaginationCount(count);
      }
    }
  }, [slideRefs, paginationRefs, getTileCount]);

  // Handles carousel state on window resize
  useEffect(() => {
    function handleResize() {
      if (isInit) {
        const trackWidth = trackRef.current?.clientWidth;
        const slideWidth = slideRefs[0].current?.clientWidth;

        if (trackWidth && slideWidth) {
          const slidesInView = Math.floor(trackWidth / slideWidth);
          const count = Math.ceil(getTileCount() / slidesInView);

          setPaginationCount(count);
        }
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isInit, getTileCount, slideRefs]);

  // Runs Intersection Observer to watch changes in carousel
  useEffect(() => {
    // Intersection Observer options passed to constructor
    const options = {
      root: trackRef.current,
      rootMargin: "0px",
      threshold: 1,
    };

    if (window.innerWidth > screens.smallDesktop) {
      options.threshold = 0.95;
    }

    // Intersection Observer callback passed to constructor
    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      const firstSlide = slideRefs[0];
      const lastSlide = slideRefs[getTileCount() - 1];

      const trackWidth = trackRef.current?.clientWidth;
      const slideWidth = slideRefs[0].current?.clientWidth;
      let slidesInView = 1;

      if (trackWidth && slideWidth)
        slidesInView = Math.floor(trackWidth / slideWidth);

      slideRefs.forEach((slide, index) => {
        if (entry.isIntersecting && entry.target === slide.current) {
          const activePaginationIndex = Math.floor(index / slidesInView);
          const activePaginationRef = paginationRefs[activePaginationIndex];

          if (hasPaginationTarget.current === false) {
            paginationRefs.forEach((item) => {
              item.current?.classList.remove(
                styles.sliderPaginationButtonActive
              );
            });
            activePaginationRef.current?.classList.add(
              styles.sliderPaginationButtonActive
            );

            if (entry.target === firstSlide.current) {
              togglePrevNextStart();
            }
            if (entry.target === lastSlide.current) {
              togglePrevNextEnd();
            }
            if (
              entry.target !== firstSlide.current &&
              entry.target !== lastSlide.current
            ) {
              togglePrevNextMiddle();
            }
          }
        }
      });
    };

    // Intersection Observer constructor
    const observer = new IntersectionObserver(intersectionCallback, options);

    // Targeting slides to be observed
    slideRefs.forEach((slide) => {
      if (slide.current) observer.observe(slide.current);
    });
  });

  const carouselSlides = tiles?.map((tile, index) => (
    <li
      key={uuidv4()}
      className={clsx(styles.sliderSlide, styles.sliderSlideSnap)}
      ref={slideRefs[index]}
      onClick={() =>
        CarouselTagManager(
          heading,
          "Tile" + " " + (index + 1) + " - " + tile.title,
          tagPage
        )
      }
    >
      {/* Activate CarouselTile when Product API ready */}
      {/* <CarouselTile page={page} tile={tile} data={data} />  */}
      <CarouselTileCMS page={page} tile={tile} />
    </li>
  ));

  // Advance slides forwards or backwards
  function goToSlides(direction: -1 | 1): void {
    let next;
    paginationRefs.forEach((ref, index) => {
      if (
        ref.current?.classList.contains(styles.sliderPaginationButtonActive)
      ) {
        next = index + direction;
      }
    });
    if (next !== undefined) handlePagination(next);
  }

  // Go to previous set of slides
  function handlePrev() {
    goToSlides(-1);
  }

  // Go to next set of slides
  function handleNext() {
    goToSlides(1);
  }

  function togglePrevNextStart() {
    previousRef.current?.setAttribute("disabled", "disabled");
    nextRef.current?.removeAttribute("disabled");
  }

  function togglePrevNextEnd() {
    previousRef.current?.removeAttribute("disabled");
    nextRef.current?.setAttribute("disabled", "disabled");
  }

  function togglePrevNextMiddle() {
    previousRef.current?.removeAttribute("disabled");
    nextRef.current?.removeAttribute("disabled");
  }

  // Handles pagination buttons on click
  function handlePagination(index: number) {
    const paginationButton = paginationRefs[index];

    const trackWidth = trackRef.current?.clientWidth;
    const slideWidth = slideRefs[0].current?.clientWidth;
    let slidesInView = 1;

    if (trackWidth && slideWidth)
      slidesInView = Math.floor(trackWidth / slideWidth);

    const slide = slideRefs[index * slidesInView];

    if (
      paginationButton.current?.classList.contains(
        styles.sliderPaginationButtonActive
      )
    )
      return;

    paginationRefs.forEach((ref) => {
      ref.current?.classList.remove(styles.sliderPaginationButtonActive);
    });

    paginationButton.current?.classList.add(
      styles.sliderPaginationButtonActive
    );

    if (index === 0) {
      togglePrevNextStart();
    } else if (index === paginationCount - 1) {
      togglePrevNextEnd();
    } else {
      togglePrevNextMiddle();
    }

    hasPaginationTarget.current = true;
    slide.current?.classList.remove(styles.sliderSlideSnap);
    trackRef.current?.classList.remove(styles.sliderTrackSnap);

    animateScrollTo(slide.current as HTMLElement, {
      elementToScroll: trackRef.current as HTMLElement,
    }).then((hasScrolledToPosition) => {
      if (hasScrolledToPosition) {
        slide.current?.classList.add(styles.sliderSlideSnap);
        trackRef.current?.classList.add(styles.sliderTrackSnap);
        hasPaginationTarget.current = false;
      }
    });
  }

  if (!component || !page) {
    return null;
  }

  return (
    <section
      className={clsx(styles.slider, {
        [styles.sliderNew]: flags?.flags && flags.flags.includes("new"),
      })}
      id={anchor ? anchor : undefined}
    >
      {/* header */}
      {flags?.flags?.includes("new") ? (
        <div className={styles.sliderHeadingNew}>
          <Headline level="h2" style={["h2"]}>
            {heading}
          </Headline>
        </div>
      ) : (
        <h2 className={styles.sliderHeading}>{heading}</h2>
      )}
      {/* Slider with tiles */}
      <ul
        className={clsx(styles.sliderTrack, styles.sliderTrackSnap)}
        ref={trackRef}
      >
        {carouselSlides}
      </ul>
      {/* Controls next/prev */}
      <ul className={styles.sliderControls}>
        <li className={styles.sliderControl}>
          {paginationCount > 1 && (
            <button
              ref={previousRef}
              className={styles.sliderPrev}
              onClick={handlePrev}
            >
              <span className="visuallyhidden">Previous</span>
            </button>
          )}
        </li>
        <li className={styles.sliderControl}>
          {paginationCount > 1 && (
            <button
              ref={nextRef}
              className={styles.sliderNext}
              onClick={handleNext}
            >
              <span className="visuallyhidden">Next</span>
            </button>
          )}
        </li>
      </ul>
      {/* Pagination */}
      {paginationCount > 1 && (
        <ul className={styles.sliderPagination}>
          {[...Array(paginationCount)].map((_, index) => {
            return (
              <li key={uuidv4()} className={styles.sliderControl}>
                <button
                  className={styles.sliderPaginationButton}
                  onClick={() => handlePagination(index)}
                  ref={paginationRefs[index]}
                ></button>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
}
