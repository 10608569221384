import { PickUpTiles } from "./PickUpTiles";
import { BrProps } from "@bloomreach/react-sdk";
import { ReactElement } from "react";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }

  const { tiles } =
    getContainerItemContent<PickUpTilesProps>(component, page) ?? {};

  const tileArray: PickUpTileProp[] = [];

  tiles?.forEach((card) => {
    const { title, content, icon } = card;

    const imageUrl = icon && page?.getContent<ImageSet>(icon)?.getOriginal();

    tileArray.push({
      title: title,
      content: content,
      icon: imageUrl,
    });
  });

  return <PickUpTiles tiles={tileArray} page={page} />;
};
