import styles from "./Footer.module.scss";
import { v4 as uuidv4 } from "uuid";
import { MenuItem } from "@bloomreach/spa-sdk";
import { rikSelect } from "services/rikSelect";
import { useContext } from "react";
import { RikContext } from "state_management/Contexts";
import clsx from "clsx";
import { removeSpaces } from "services/textHelpers";
import { FooterTagManager } from "./FooterTagManager";

export const MenuSection = ({ data }: FooterMenuProps) => {
  const rik = useContext(RikContext);
  const items = data?.getChildren();
  const itemList = rikSelect(items, rik);

  return (
    <section className={styles.menuSection}>
      <div className={styles.menuTitle} data-testid="footer-item">
        <h6>{data.getName()}</h6>
      </div>
      <div className={styles.menuContent}>
        {itemList?.map((child: MenuItem) => {
          const name = child.getName();

          return (
            <a
              className={clsx("scTrack scNavLink", styles.menuLink)}
              href={child.getLink()?.href}
              key={uuidv4()}
              target="_blank"
              rel="noreferrer"
              data-pniautomationid={removeSpaces({
                string: "Footer" + name,
                keepUppercase: true,
              })}
              data-sctype="scNavLink"
              data-scsticky="false"
              data-scnavtype="footer"
              data-scvalue={removeSpaces({
                string: name,
                keepUppercase: true,
              })}
              data-scparentvalue={removeSpaces({
                string: data.getName(),
                keepUppercase: true,
              })}
              onClick={() => FooterTagManager(data.getName(), name)}
            >
              {name}
            </a>
          );
        })}
      </div>
    </section>
  );
};
