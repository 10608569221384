import { Tile } from "./Tile";
import { v4 as uuidv4 } from "uuid";
import styles from "./LongProductTile.module.scss";

export const Container = ({ sectionTitle, tiles }: LongProductTileFCProps) => {
  return (
    <article className={styles.wrapper}>
      {sectionTitle && <h2 className={styles.sectionTitle}>{sectionTitle}</h2>}
      <section className={styles.container}>
        {tiles?.map((tile) => (
          <Tile key={uuidv4()} data={tile} />
        ))}
      </section>
    </article>
  );
};
