import { ReactElement } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ContainerItem, getContainerItemContent } from "@bloomreach/spa-sdk";
import { ScrollableTiles } from "./ScrollableTiles";

export function Index({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null {
  if (!component || !page) {
    return <div>No component or page available</div>;
  }

  //Receiving data from Bloomreach
  const { headlineTitle, pmaccessoriestile } =
    getContainerItemContent<ScrollableTilesProps>(component, page) ?? {};

  const containerData = {
    headlineTitle: headlineTitle,
    pmaccessoriestile: pmaccessoriestile,
  };

  return <ScrollableTiles containerData={containerData} page={page} />;
}
