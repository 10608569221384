import Script from "next/script";
import { useContext, useEffect, useState } from "react";
import { RikContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";

export function ThirdPartyScripts({ pagePath }: any) {
  const rikMode = useContext(RikContext);
  const [chatUrl, setChatUrl] = useState("");

  /* Push kiosk mode, page type, and product category check for GTM */
  const [gtmPageData, setGtmPageData] = useState({
    kioskMode: "",
    page_type: "",
    product_category: "",
  });
  const page = PageCheck(pagePath);

  useEffect(() => {
    const path = pagePath.path;
    const homePath = "/services/printing/";
    let category = "";
    if (path === homePath) category = "homepage";
    if (path.length > homePath.length) {
      category = path.substring(homePath.length, path.length - 1);
    }

    const updatedGtmPageData = {
      kioskMode: rikMode ? "yes" : "no",
      page_type: page,
      product_category: category,
    };

    setGtmPageData(updatedGtmPageData);
  }, [pagePath.path, rikMode, page]);

  useEffect(() => {
    if (
      gtmPageData.kioskMode !== "" &&
      gtmPageData.page_type !== "" &&
      gtmPageData.product_category !== ""
    ) {
      window.dataLayer.push(gtmPageData);
    }
  }, [gtmPageData]);

  // Setting chat script url based on host url:
  useEffect(() => {
    const hostURL = window.location.hostname;
    const regResult = /^www\.staples\.com$/;

    regResult.test(hostURL)
      ? setChatUrl(
          "https://www.staples.com/sbd/content/inc/voc/chat/static/StaplesChat.js"
        )
      : setChatUrl(
          "https://qe101.staples.com/sbd/content/inc/voc/chat/static/StaplesChat.js"
        );
  }, []);

  return (
    <>
      {/* Google Tag Manager data layer*/}
      <Script id="gtm-push" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'GTM-52WC86', {
            page_path: window.location.pathname,
          });
        `}
      </Script>
      {/* Adding Google Analytics tags to head (and excluding the script from local env) */}
      {process.env.NEXT_PUBLIC_LOAD_SCRIPTS === "true" && (
        <Script id="google-analytics" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-52WC86');`}
        </Script>
      )}
      {/* Google Tag Manager iFrame */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-52WC86"
          height="0"
          width="0"
          id="tagManagerIframe"
        ></iframe>
      </noscript>
      <style jsx>{`
        #tagManagerIframe {
          display: none !important;
          visibility: hidden !important;
        }
      `}</style>
      {/* Add Chat Script */}
      {chatUrl !== "" && (
        <Script id="chat" strategy="lazyOnload" src={chatUrl}></Script>
      )}
      {/* Elevio Help Widget Script */}
      <Script id="elevio" strategy="lazyOnload">
        {`
          var _elev = window._elev || {}
          _elev.account_id = '5554c20724ca0';

          if (typeof pni !== "undefined" && pni.lazyload)  {
          pni.lazyload.script("https://static.elev.io/js/v3.js");
          } else {
          var i, e; i = document.createElement("script"), i.type = 'text/javascript'; i.async = 1, i.src = "https://static.elev.io/js/v3.js", e = document.getElementsByTagName("script")[0], e.parentNode.insertBefore(i, e);
          }
        `}
      </Script>
    </>
  );
}
