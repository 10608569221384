import { BrPageContext } from "@bloomreach/react-sdk";
import { ImageSet, Reference } from "@bloomreach/spa-sdk";
import { useEffect, useState, useContext } from "react";
import { GradientSetter } from "services/gradientSetter";

interface ColorProps {
  colorSchema: ColorSchema[] | undefined;
}

export const useBackgroundColor = (background: ColorProps) => {
  const [bgColor, setBgColor] = useState<string>("");
  const page = useContext(BrPageContext);

  useEffect(() => {
    if (background && background.colorSchema) {
      const rgba = background.colorSchema[0];
      if (rgba?.contentType === "brxsaas:pmSolidColorSchema") {
        const R = rgba.red;
        const G = rgba.green;
        const B = rgba.blue;
        const A = rgba.alpha;

        setBgColor(`rgba(${R},${G},${B},${A})`);
      }
      if (rgba?.contentType === "brxsaas:pmGradientColorSchema") {
        const direction = rgba.gradientDirection.selectionValues[0].key;
        const type = rgba.gradientType.selectionValues[0].key;
        const gradientType =
          type === "Linear" ? "linear-gradient" : "radial-gradient";
        const gradientDirection =
          type === "Linear" ? direction.toLowerCase() : "circle";
        const colorsList = rgba.colors;

        const gradientColors = GradientSetter(colorsList);

        setBgColor(`${gradientType}(${gradientDirection}, ${gradientColors})`);
      }
    }
  }, [background, page]);

  return bgColor;
};

export const useBackgroundImage = (image: Reference) => {
  const page = useContext(BrPageContext);
  const [bgUrl, setBgUrl] = useState<string | undefined>("");
  useEffect(() => {
    const brImageData =
      image && page?.getContent<ImageSet>(image)?.getOriginal();
    setBgUrl(brImageData?.getUrl());
  }, [page, image]);
  return bgUrl;
};
