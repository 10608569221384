import styles from "./HeadingBlog.module.scss";
import { ContainerProps } from "./types";
import { BrRichTextContent } from "../BrRichTextContent";
import clsx from "clsx";
import { linkAttributes } from "services/linkAttributes";

export const Container = ({ page, data }: ContainerProps) => {
  const {
    header,
    headerSize,
    textBlock,
    fontcolor,
    backgroundColor,
    ctaButton,
    paragraphStyle,
    subheadingSection,
  } = data;
  const header_size = headerSize.selectionValues[0]["label"];
  const font_color = fontcolor.selectionValues[0]["label"];
  const paragraph_style = paragraphStyle.selectionValues[0]["label"];
  const no_bg_color =
    backgroundColor.red === 255 &&
    backgroundColor.green === 255 &&
    backgroundColor.blue === 255;

  return (
    <article className={styles.container}>
      <section
        className={clsx(styles.section, {
          [styles.sectionPadding]: !no_bg_color,
        })}
        style={{
          backgroundColor: `rgba(${backgroundColor.red}, ${backgroundColor.green}, ${backgroundColor.blue}, ${backgroundColor.alpha})`,
        }}
      >
        <div className={styles.sectionHeader}>
          <div
            className={clsx({
              [styles.hH1]: header_size === "H1",
              [styles.hH2]: header_size === "H2",
              [styles.hH3]: header_size === "H3",
              [styles.hH4]: header_size === "H4",
            })}
          >
            {header}
          </div>

          {textBlock && (
            <div
              className={clsx({
                [styles.whiteFont]: font_color === "White",
                [styles.blackFont]: font_color === "Black",
                [styles.pDefaultStyle]: paragraph_style === "Default",
                [styles.pSmallStyle]: paragraph_style === "Small",
                [styles.pLargeStyle]: paragraph_style === "Large",
              })}
            >
              <BrRichTextContent
                page={page!}
                content={{ html: textBlock.value }}
              />
            </div>
          )}

          {subheadingSection.map((el, index) => {
            const subheading_size =
              el.subheadingSize.selectionValues[0]["label"];
            return (
              <>
                {el.subheading !== "" ? (
                  <div
                    key={index}
                    className={clsx({
                      [styles.whiteFont]: font_color === "White",
                      [styles.blackFont]: font_color === "Black",
                    })}
                  >
                    <div
                      className={clsx({
                        [styles.subheadingSize1]: subheading_size === "X-Large",
                        [styles.subheadingSize2]: subheading_size === " Large",
                        [styles.subheadingSize3]: subheading_size === " Medium",
                        [styles.subheadingSize4]:
                          subheading_size === " Small/Bold",
                        [styles.subheadingSize5]:
                          subheading_size === " Small/Medium",
                        [styles.subheadingSize6]:
                          subheading_size === " X-Small",
                      })}
                    >
                      {el.subheading}
                    </div>
                    {el.subheadingTextBlock.value !== "" && (
                      <div
                        className={clsx({
                          [styles.pDefaultStyle]: paragraph_style === "Default",
                          [styles.pSmallStyle]: paragraph_style === "Small",
                          [styles.pLargeStyle]: paragraph_style === "Large",
                        })}
                      >
                        <BrRichTextContent
                          page={page!}
                          content={{ html: el.subheadingTextBlock.value }}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
              </>
            );
          })}
        </div>

        {ctaButton.length > 0 ? (
          <div className={styles.ctaButton}>
            {ctaButton.map((el, index) => {
              const address = linkAttributes(el.address, el.newTab);
              return (
                <a key={index} {...address}>
                  <div
                    className={
                      index === 0
                        ? styles.buttonPrimary
                        : styles.buttonSecondary
                    }
                  >
                    {el.title ? el.title : "CTA"}
                  </div>
                </a>
              );
            })}
          </div>
        ) : null}
      </section>
    </article>
  );
};
