import { BrRichTextContent } from "../BrRichTextContent";
import styles from "../CarouselTile/CarouselTile.module.scss";
import Image from "next/legacy/image";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import { imagePlaceholder } from "../../constants";
import { useContext } from "react";
import { MetaFlagsContext } from "state_management/Contexts";

/* eslint-disable @next/next/no-img-element */

export const CarouselTileCMS = ({
  title,
  caption,
  link = "https://www.staples.com/services/printing/",
  image,
  badge,
  startingAt,
  promo,
  sameDay,
  optionText,
  page,
}: CarouselTileCmsFCProps) => {
  const flags = useContext(MetaFlagsContext);
  return (
    <article className={styles.wrapper}>
      <a href={link}>
        <section
          className={clsx(styles.container, {
            [styles.containerNew]: flags?.flags && flags.flags.includes("new"),
          })}
        >
          <figure className={styles.figure}>
            {badge !== "" && (
              <section
                key={uuidv4()}
                className={styles.badge}
                data-testid="badge"
              >
                <p>{badge}</p>
              </section>
            )}
            <div className={styles.image}>
              <Image
                src={image ? image?.getUrl() : imagePlaceholder}
                alt=""
                layout="fill"
                objectFit="cover"
                className={styles.imageImg}
              />
            </div>
            <figcaption className={styles.figcaption}>
              <h3 className={styles.figcaptionTitle}>
                {title ? title : "Title"}
              </h3>
              <div className={styles.figcaptionCaption}>
                {caption && (
                  <BrRichTextContent
                    page={page!}
                    content={{ html: caption.value }}
                  />
                )}
              </div>
              <section className={styles.figcaptionPrice}>
                <span
                  className={clsx(styles.figcaptionPriceStarting, {
                    [styles.figcaptionPriceData]:
                      promo === "" && startingAt === "",
                  })}
                >
                  {promo === "" && startingAt === ""
                    ? "Learn more"
                    : "Starting at"}
                </span>

                <div
                  className={clsx(styles.figcaptionPriceData, {
                    [styles.figcaptionRed]: promo !== "",
                  })}
                  data-testid="startPrice"
                >
                  <p>{promo !== "" ? promo : startingAt}</p>
                </div>

                <div
                  className={styles.figcaptionPriceRegPrice}
                  data-testid="regPrice"
                >
                  {promo !== "" && <p>Reg. {startingAt}</p>}
                </div>
              </section>

              <div
                data-testid="sameDay"
                className={clsx(styles.figcaptionSameDay, {
                  [styles.figcaptionSameDayDisabled]: !sameDay,
                })} //Using styles switch instead of conditional rendering to save section height.
              >
                {sameDay && (
                  <div className={styles.figcaptionSameDayContent}>
                    <div className={styles.icon}>
                      <img
                        src="/services/printing/static-pages/icons/carousel-icons-sameday.png"
                        alt=""
                      />
                    </div>
                    <p>{optionText ? optionText : "Same-day options"}</p>
                  </div>
                )}
              </div>
            </figcaption>
          </figure>
        </section>
      </a>
    </article>
  );
};
