export const VideoContainer = ({
  videoId,
  width,
  height,
  autoplay,
}: VideoComponentProps) => {
  const autoplayQuery = autoplay ? "&autoplay=1" : "";

  return (
    <div>
      <iframe
        width={`${width}`}
        height={`${height}`}
        src={`https://www.youtube.com/embed/${videoId}?mute=1${autoplayQuery}`}
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        title="Embedded youtube"
        style={{ borderRadius: "4px", border: "0" }}
        allowFullScreen
        data-testid="VideoContainer"
      />
    </div>
  );
};
