import styles from "./Header.module.scss";
import { WrapperProps } from "./types";
import { Logo } from "../Logo";
import HamburgerIcon from "@/public/static-pages/icons/navbar/hamburger.svg";
import Script from "next/script";

export function Header({ children, dialogOpen }: WrapperProps) {
  return (
    <>
      <Script
        type="text/javascript"
        strategy="afterInteractive"
        src="//staplespni.btttag.com/btt.js"
      />
      <header id="mainHeader" className={styles.header}>
        <div className={styles.leftSide}>
          <button
            className={styles.hamburger}
            onClick={dialogOpen}
            aria-label="Open menu"
          >
            <HamburgerIcon />
          </button>
          <div className={styles.headerLogoMobile}>
            <Logo mini />
          </div>
          <div className={styles.headerLogoDesktop}>
            <Logo />
          </div>
        </div>
        {children}
      </header>
    </>
  );
}
