import { useEffect, useState } from "react";
import {
  RikContext,
  CustomerEmailAddressContext,
  RewardsContext,
  UserStatusContext,
  SessionIdContext,
} from "state_management/Contexts";
import Cookies from "universal-cookie";
import { sessionTracker, IMemberId } from "services/sessionTracker";

export const Index = ({ children }: any) => {
  const [rikMode, setRikMode] = useState(false);
  const [customerEmail, setCustomerEmail] = useState("");
  const [rewardsMember, setRewardsMember] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [sessionId, setSessionId] = useState("");

  useEffect(() => {
    const cookies = new Cookies();
    const customerEmailAddress: string = cookies.get("customerEmailAddress");
    const rewards: string = cookies.get("StaplesRewardsInfo");
    const status: string = cookies.get("SMSESSION");
    const session: string = cookies.get("session-id");
    const isRik: string = cookies.get("RikModeCheck");
    const kioskKey: string = cookies.get("KioskContextKey");
    const memberId: string = cookies.get("member-id");
    const pniUser: PniUsrObject | string = cookies.get("PniUsrCtx");
    let newMemberId: IMemberId = {} as IMemberId;

    if (!sessionTracker.IsValid(memberId)) {
      newMemberId = sessionTracker.Create();
    } else {
      let isUserSignedIn = false;
      if (pniUser instanceof Object) isUserSignedIn = true;
      newMemberId = sessionTracker.UserStatusUpdate(memberId, isUserSignedIn);
    }
    cookies.set(
      newMemberId.cookieName,
      newMemberId.cookieValue,
      newMemberId.options
    );

    setCustomerEmail(customerEmailAddress);
    setRewardsMember(rewards);
    setUserStatus(status);
    setSessionId(session);

    if (isRik === "true" && kioskKey !== "" && kioskKey !== undefined)
      setRikMode(true);
  }, [customerEmail, rikMode, rewardsMember, userStatus, sessionId]);

  return (
    <RikContext.Provider value={rikMode}>
      <CustomerEmailAddressContext.Provider value={customerEmail}>
        <RewardsContext.Provider value={rewardsMember}>
          <UserStatusContext.Provider value={userStatus}>
            <SessionIdContext.Provider value={sessionId}>
              {children}
            </SessionIdContext.Provider>
          </UserStatusContext.Provider>
        </RewardsContext.Provider>
      </CustomerEmailAddressContext.Provider>
    </RikContext.Provider>
  );
};
