import styles from "../Breadcrumbs/Breadcrumbs.module.scss";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import { useResize } from "hooks/useResize";

export const Breadcrumbs = ({
  breadcrumbs,
  search,
  isBanner,
}: BreadcrumbsProps) => {
  const windowSize = useResize();
  const isProd = process.env.NODE_ENV === "production";

  const prevBreadcrumbs = breadcrumbs[breadcrumbs.length - 2];
  return (
    <>
      <ul className={clsx(styles.bcContainer, styles.smBreadcrumb)}>
        <li className={styles.viewAll}>
          {breadcrumbs.length === 1 ? (
            <a
              href={
                isProd
                  ? `${process.env.NEXT_PUBLIC_BASEPATH}`
                  : `${process.env.NEXT_PUBLIC_BASEPATH}${search}`
              }
            >
              See all Print & Marketing Services
            </a>
          ) : (
            <a href={prevBreadcrumbs?.key + search}>
              See all {prevBreadcrumbs?.value}
            </a>
          )}
        </li>
      </ul>
      <ul
        className={clsx(styles.bcContainer, styles.lgBreadcrumb, {
          [styles.bcContainerHide]:
            (isBanner && breadcrumbs.length > 1) || !isBanner,
        })}
      >
        <li
          className={clsx(styles.first, {
            [styles.firstBanner]: isBanner,
            [styles.firstHide]: isBanner && breadcrumbs.length > 1,
          })}
          data-testid="home_bc"
        >
          <a
            href={
              isProd
                ? `${process.env.NEXT_PUBLIC_BASEPATH}`
                : `${process.env.NEXT_PUBLIC_BASEPATH}${search}`
            }
          >
            Print & Marketing Services
          </a>
        </li>
        {breadcrumbs.map((bc) => {
          return (
            <li
              key={uuidv4()}
              className={clsx(styles.item, {
                [styles.itemBanner]: isBanner,
                [styles.itemHide]: isBanner && breadcrumbs.length > 1,
              })}
              data-testid="added_bc"
            >
              <a href={bc?.flag === "int" ? bc.key + search : bc?.key}>
                {bc.value}{" "}
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
};
